import { useTheme } from '@emotion/react';
import React, { useEffect } from 'react';

import { Box } from '~/components/blocks';
import { QuestionnaireSheet } from '~/components/partials/QuestionnaireSheet';
import {
  QuestionnaireSheetAnswerFragment,
  QuestionnaireSheetFragment,
  useGetWebVisitorQuestionnaireSheetLazyQuery,
  WebVisitorQuestionnaireSheetFragment,
} from '~/graphql';
import { getNode } from '~/graphql/utility';

type Props = {
  appointmentId: string;
  partner?: boolean;
};

export const QuestionnairePane = React.memo((props: Props) => {
  const theme = useTheme();
  const [getQuestionnaires, { data, loading }] = useGetWebVisitorQuestionnaireSheetLazyQuery();

  const answers = getNode(data, 'Appointment') as WebVisitorQuestionnaireSheetFragment;
  const sheet: QuestionnaireSheetFragment | undefined =
    answers?.questionnaires.length > 0
      ? {
          answers: answers?.questionnaires.map((question) => {
            const answer: QuestionnaireSheetAnswerFragment = {
              title: question.title,
              value: question.value,
            };
            return answer;
          }),
          updatedAt: answers?.createdAt,
        }
      : undefined;

  useEffect(() => {
    getQuestionnaires({ variables: { appointmentId: props.appointmentId } });
  }, [getQuestionnaires, props.appointmentId]);

  return (
    <Box padding={theme.space.l}>
      <QuestionnaireSheet copyable={!props.partner} loading={loading} sheet={sheet} />
    </Box>
  );
});

QuestionnairePane.displayName = 'QuestionnairePane';
