import { useTheme } from '@emotion/react';
import React, { useCallback, useEffect, useState } from 'react';

import { Box, EntryList, Flex, Grid, Icon, Text } from '~/components/blocks';
import { AppointmentStatusLabel, MemoField, ReceptionTypeIcon } from '~/components/partials';
import { PrescriptionCollapse } from '~/components/partials/PrescriptionCollapse';
import { useUpdateDescriptionMutation, WebVisitorAppointmentDetailFragment } from '~/graphql';
import { translateReason } from '~/utils/appointments';
import { Label } from '~/utils/label';

type Props = {
  appointment: WebVisitorAppointmentDetailFragment;
  partner?: boolean;
};

export const WebVisitorProfile = React.memo((props: Props) => {
  const { appointment, partner } = props;
  const { webVisitor, createdAt, start, end, description, status, cancellationReason } =
    appointment;
  const theme = useTheme();

  const [latestMemo, setLatestMemo] = useState({ memo: '', mutationCalled: false });
  const [updateDescription, { loading: updateLoading }] = useUpdateDescriptionMutation({
    onCompleted: (_data) => {
      // Apollo or useMemo のキャッシュまわりの不整合が発生するので、メモ更新後は Mutation の返り値を使う
      // FYI https://github.com/medley-inc/pharms/issues/1852
      const latestDescription = _data?.updateAppointmentDescription?.appointment?.description || '';

      // updateDescriptionData はリロード走るまで値を保持し続けるので、別の受付ドロワーを開いたタイミングでリセットできるようにステート管理する
      setLatestMemo({ memo: latestDescription, mutationCalled: true });
    },
  });

  const handleChangeMemo = useCallback(
    (memo: string) => {
      if (appointment) {
        updateDescription({
          variables: {
            input: {
              appointmentId: appointment.id,
              description: memo,
            },
          },
        });
      }
    },
    [appointment, updateDescription],
  );

  useEffect(() => {
    // appointmentが切り替わった時にリセットする
    setLatestMemo({ memo: '', mutationCalled: false });
  }, [appointment]);

  return (
    <>
      <Box position="relative" height="100%" overflow="auto">
        <Grid
          gridTemplateColumns="min-content auto"
          gridTemplateRows="50px min-content"
          alignItems="center"
        >
          <Flex justifyContent="center">
            <ReceptionTypeIcon size="xxxl" mr="xxs" />
          </Flex>
          <Flex justifyItems="start">
            <Text fontWeight="bold" size="m" paddingLeft={theme.space.s}>
              対面服薬指導
            </Text>
            <Box marginLeft="auto">
              <AppointmentStatusLabel status={status} />
            </Box>
          </Flex>
          {start && end && (
            <>
              <Flex justifyContent="center">
                <Icon icon="time" size="xl" />
              </Flex>
              <Flex justifyItems="start">
                <Text fontWeight="bold" size="m" ml={theme.space.s}>
                  {Label.YYYYMMDDja(start)}
                </Text>
                <Text fontWeight="bold" size="m" ml={theme.space.s}>
                  {Label.HHMM(start)}-{Label.HHMM(end)}
                </Text>
              </Flex>
            </>
          )}
        </Grid>
      </Box>
      <PrescriptionCollapse printable={!partner} appointmentId={appointment.id} />
      <Box marginTop={theme.space.xl}>
        <EntryList>
          <EntryList.Head>氏名</EntryList.Head>
          <EntryList.Body>{`${webVisitor?.familyName} ${webVisitor?.givenName}`}</EntryList.Body>
        </EntryList>
        <EntryList>
          <EntryList.Head>氏名（カナ）</EntryList.Head>
          <EntryList.Body>{`${webVisitor?.phoneticFamilyName} ${webVisitor?.phoneticGivenName}`}</EntryList.Body>
        </EntryList>
        <EntryList>
          <EntryList.Head>生年月日</EntryList.Head>
          <EntryList.Body>
            {webVisitor ? Label.warekiBirthDate(webVisitor.birthDate) : 'ー'}
          </EntryList.Body>
        </EntryList>
        <EntryList>
          <EntryList.Head>性別</EntryList.Head>
          <EntryList.Body>{webVisitor ? Label.sex(webVisitor.sex) : 'ー'}</EntryList.Body>
        </EntryList>
        <EntryList>
          <EntryList.Head>電話番号（携帯）</EntryList.Head>
          <EntryList.Body>{webVisitor?.mobilePhone}</EntryList.Body>
        </EntryList>
        <EntryList>
          <EntryList.Head>申し込み日時</EntryList.Head>
          <EntryList.Body>
            <Flex>
              <Box>
                <Text size="m">{createdAt ? Label.YYYYMMDDja(createdAt) : null}</Text>
                <Text size="m">{createdAt ? Label.HHMM(createdAt) : null}</Text>
              </Box>
              <Box>
                <Text size="m" ml={theme.space.s}>
                  （処方箋ネット受付）
                </Text>
              </Box>
            </Flex>
          </EntryList.Body>
        </EntryList>
        <EntryList>
          <EntryList.Head>メモ</EntryList.Head>
          <EntryList.Body>
            <MemoField
              disabled={updateLoading}
              value={latestMemo.mutationCalled ? latestMemo.memo : description || ''}
              onChange={handleChangeMemo}
            />
          </EntryList.Body>
        </EntryList>
        {cancellationReason && (
          <EntryList mt={theme.space.l}>
            <EntryList.Head>キャンセル内容</EntryList.Head>
            <EntryList.Body>
              <Text fontWeight="bold">
                {translateReason(cancellationReason, status, !!appointment.waitingForChargeAt)}
              </Text>
            </EntryList.Body>
          </EntryList>
        )}
      </Box>
    </>
  );
});

WebVisitorProfile.displayName = 'WebVisitorProfile';
