import React, { useEffect } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { Box, Loader, Modal } from '~/components/blocks';
import { WebVisitorChargePanel } from '~/components/partials/WebVisitorChargePanel';
import { webVisitorChargeModalState } from '~/state/reception/atoms';

import { useFetchAppointment } from './use-fetch-appointment';

export const WebVisitorChargeModal = () => {
  const state = useRecoilValue(webVisitorChargeModalState);
  const resetState = useResetRecoilState(webVisitorChargeModalState);

  const appointment = useFetchAppointment();

  useEffect(() => {
    if (!state.isOpen) {
      resetState();
    }
  }, [resetState, state.isOpen]);

  return (
    <Modal open={state.isOpen} size="s" onClose={resetState}>
      <Modal.Header>会計</Modal.Header>
      {appointment ? (
        <WebVisitorChargePanel appointment={appointment} />
      ) : (
        <Modal.Body>
          <Box position="relative" height="240px">
            <Loader open inside />
          </Box>
        </Modal.Body>
      )}
    </Modal>
  );
};
