import React, { useEffect, useMemo, useRef } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';

import { ScrollBox } from '~/components/blocks';
import { TracingReportPanel } from '~/components/partials/TracingReportPanel';
import {
  GetWebVisitorTracingReportsQuery,
  TracingReportFragment,
  useGetWebVisitorTracingReportsLazyQuery,
} from '~/graphql';
import { getNode } from '~/graphql/utility';
import { usePreviousValue } from '~/hooks/use-previous-value';
import { patientDetailDialogState } from '~/state/partials/patient_detail_dialog/atoms';
import { TabType } from '~/state/partials/patient_detail_dialog/types';
import { tracingReportPageInfoState } from '~/state/partials/tracingreport_panel/atoms';

type Props = {
  webVisitorId: string;
};

const getTotalPage = (data: GetWebVisitorTracingReportsQuery) => {
  const tracingReports = getNode(data, 'DirectVisitor')?.tracingReports;
  return tracingReports ? tracingReports.pagesCount : 1;
};

export const TracingReport = (props: Props) => {
  const { webVisitorId } = props;
  const [pageInfo, setPageInfo] = useRecoilState(tracingReportPageInfoState);
  const patientDetailDialog = useRecoilValue(patientDetailDialogState);
  const isOpen = patientDetailDialog.isOpen;

  const scrollRef = useRef<RefAttributeType<typeof ScrollBox> | null>(null);

  const [getTracingReports, { data, loading }] = useGetWebVisitorTracingReportsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (result) => {
      setPageInfo((_state) => ({ ..._state, totalPage: getTotalPage(result) }));
      scrollRef.current?.toTop();
    },
  });

  const prevData = usePreviousValue(data);
  const currentData = data || prevData;
  const tracingReports: TracingReportFragment[] = useMemo(() => {
    let results: TracingReportFragment[] = [];
    const patient = getNode(currentData, 'WebVisitor');
    if (patient) {
      results = patient.tracingReports.nodes.map((n) => {
        return { id: n.id, createdAt: n.createdAt, data: n.data };
      });
    }

    return results;
  }, [currentData]);

  useEffect(() => {
    if (pageInfo.target == 'GuestPatient' && webVisitorId && !isOpen) {
      getTracingReports({
        variables: {
          webVisitorId: webVisitorId,
          page: pageInfo.page,
          perPage: pageInfo.perPage,
        },
      });
    }
  }, [
    isOpen,
    getTracingReports,
    webVisitorId,
    pageInfo.page,
    pageInfo.perPage,
    pageInfo.target,
    patientDetailDialog.isOpen,
  ]);

  const handleCreateTracingReport = useRecoilCallback(
    ({ set }) =>
      () => {
        if (webVisitorId) {
          set(patientDetailDialogState, (_state) => ({
            ..._state,
            patientId: webVisitorId,
            isOpen: true,
            type: 'tracingreport' as TabType,
          }));
        }
      },
    [webVisitorId],
  );

  return (
    <TracingReportPanel
      onCreate={handleCreateTracingReport}
      loading={loading}
      tracingReports={tracingReports}
    />
  );
};
