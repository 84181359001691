import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { useGetWebAppointmentForChargeLazyQuery, WebAppointmentForChargeFragment } from '~/graphql';
import { getNode } from '~/graphql/utility';
import { webVisitorChargeModalState } from '~/state/reception/atoms';

export const useFetchAppointment = (): WebAppointmentForChargeFragment | null => {
  const { isOpen, appointmentId } = useRecoilValue(webVisitorChargeModalState);
  const [getAppointment, { data }] = useGetWebAppointmentForChargeLazyQuery();

  const appointment = getNode(data, 'Appointment') || null;

  useEffect(() => {
    if (isOpen && appointmentId) {
      getAppointment({ variables: { appointmentId } });
    }
  }, [appointmentId, getAppointment, isOpen]);

  return appointment;
};
