import React from 'react';

import { Box } from '~/components/blocks';
import { WebVisitorAppointmentDetailFragment } from '~/graphql';
import { theme } from '~/styles/theme';

import { WebVisitorProfile } from './WebVisitorProfile';

type Props = {
  appointment: WebVisitorAppointmentDetailFragment;
  partner?: boolean;
};

export const ReceptionPane = React.memo((props: Props) => {
  const { appointment, partner } = props;

  return (
    <Box position="relative" height="100%" overflow="auto">
      <Box padding={theme.space.l} overflow="auto">
        <WebVisitorProfile appointment={appointment} partner={partner} />
      </Box>
    </Box>
  );
});

ReceptionPane.displayName = 'ReceptionPane';
