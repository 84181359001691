import { useTheme } from '@emotion/react';
import React, { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { Box } from '~/components/blocks';
import { tracingReportPageInfoState } from '~/state/partials/tracingreport_panel/atoms';
import { webVisitorFileState } from '~/state/reception/atoms';

import { TracingReport } from './TracingReport';

type Props = {
  webVisitorId: string;
};
export const FilePane = (props: Props) => {
  const theme = useTheme();
  const { webVisitorId } = props;
  const setPageInfo = useSetRecoilState(tracingReportPageInfoState);
  const [webVisitorFile, setWebVisitorFile] = useRecoilState(webVisitorFileState);

  useEffect(() => {
    if (webVisitorFile.webVisitorId !== webVisitorId) {
      setPageInfo((_state) => ({
        ..._state,
        target: 'GuestPatient',
        targetId: webVisitorFile.webVisitorId,
        page: 1,
        totalPage: 1,
      }));

      setWebVisitorFile((_state) => ({
        ..._state,
        webVisitorId,
      }));
    }
  }, [webVisitorId, webVisitorFile.webVisitorId, setPageInfo, setWebVisitorFile]);

  return (
    <Box position="relative" height="100%" overflow="auto" padding={theme.space.l}>
      <TracingReport webVisitorId={webVisitorId} />
    </Box>
  );
};
